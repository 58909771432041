import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_lmdaDUlOJp from "/app/node_modules/.c12/github_y_media_A8FrquQPxr/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_ghbUAjaD3n from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _01_cacheControl_ub1KvZMv2V from "/app/node_modules/.c12/github_y_media_A8FrquQPxr/plugins/01.cacheControl.ts";
import _03_uid_client_8lHtss41rm from "/app/node_modules/.c12/github_y_media_A8FrquQPxr/plugins/03.uid.client.ts";
import GTM_client_NaRtjgDQMR from "/app/node_modules/.c12/github_y_media_A8FrquQPxr/plugins/GTM.client.ts";
import api_4uav62pIFU from "/app/node_modules/.c12/github_y_media_A8FrquQPxr/plugins/api.ts";
import directives_SS7pL9EPKa from "/app/node_modules/.c12/github_y_media_A8FrquQPxr/plugins/directives.ts";
import firebase_client_pCfdpzQC3q from "/app/node_modules/.c12/github_y_media_A8FrquQPxr/plugins/firebase.client.ts";
import integrations_client_sANwaDzqu7 from "/app/node_modules/.c12/github_y_media_A8FrquQPxr/plugins/integrations.client.ts";
import remain_client_AWneXFDSZ7 from "/app/node_modules/.c12/github_y_media_A8FrquQPxr/plugins/remain.client.ts";
import appInit_client_5OlePbdpwf from "/app/plugins/appInit.client.ts";
import error_ldt3PQauZ0 from "/app/plugins/error.ts";
import general_WpAkvTZxL0 from "/app/plugins/general.ts";
import maska_UHaKf2z1iQ from "/app/plugins/maska.ts";
import scroll_page_T02YBELFpL from "/app/plugins/scroll-page.ts";
import site_popups_client_Wh8U5xF1BY from "/app/plugins/site-popups.client.ts";
import plugin_client_QHzhVBB2Ll from "/app/.nuxt/nuxt-booster/plugin.client.js";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_lmdaDUlOJp,
  plugin_ghbUAjaD3n,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  _01_cacheControl_ub1KvZMv2V,
  _03_uid_client_8lHtss41rm,
  GTM_client_NaRtjgDQMR,
  api_4uav62pIFU,
  directives_SS7pL9EPKa,
  firebase_client_pCfdpzQC3q,
  integrations_client_sANwaDzqu7,
  remain_client_AWneXFDSZ7,
  appInit_client_5OlePbdpwf,
  error_ldt3PQauZ0,
  general_WpAkvTZxL0,
  maska_UHaKf2z1iQ,
  scroll_page_T02YBELFpL,
  site_popups_client_Wh8U5xF1BY,
  plugin_client_QHzhVBB2Ll
]