<template>
  <ClientOnly>
    <TransitionRoot appear :show="systemDialogStore.isOpen" as="template">
      <Dialog
        as="div"
        :open="systemDialogStore.isOpen"
        class="relative z-[999]"
        @close="closeSystemDialog"
      >
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-50" />
        </TransitionChild>

        <div
          class="fixed inset-0 flex h-full w-full items-center justify-center p-4"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="flex h-auto max-h-full w-[680px] flex-col bg-white px-7.5 shadow-xl transition-all"
            >
              <div
                class="mb-5 flex border-b border-gray-strokes pb-5 pt-7.5 text-3xl font-medium text-primary"
              >
                {{ systemDialogStore.dialogState.title }}
                <UiButton
                  variant="clear"
                  variant-type="icon"
                  class="ml-auto"
                  :aria-label="
                    $t('ariaLabelClose', {
                      title: $t('systemDialogStore.dialogState.title'),
                    })
                  "
                  @click="closeSystemDialog"
                >
                  <UiIcon name="close" class="h-6 w-6" />
                </UiButton>
              </div>
              <div>{{ systemDialogStore.dialogState.text }}</div>
              <div class="flex justify-center gap-3 py-7.5">
                <UiButton
                  v-if="isConfirm"
                  outlined
                  color="primary"
                  width="148px"
                  class="text-xs"
                  @click="cancel"
                >
                  {{ systemDialogStore.dialogState?.cancelBtnText }}
                </UiButton>
                <UiButton
                  class="bg-black text-xs text-white"
                  width="148px"
                  @click="success"
                >
                  {{ systemDialogStore.dialogState?.successBtnText }}
                </UiButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </ClientOnly>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  TransitionRoot,
  TransitionChild,
} from "@headlessui/vue";
import { useSystemDialogStore } from "~/stores/systemDialog";
const systemDialogStore = useSystemDialogStore();

watch(
  () => systemDialogStore.isOpen,
  () => {
    if (systemDialogStore.isOpen) {
      (document.activeElement as HTMLElement)?.blur?.();
    }
  },
);

const { cancel, success, isConfirm, closeSystemDialog } = useSystemDialogs();
</script>
