import { defineStore } from "pinia";
import {
  BlockContent,
  IBlockTranslation,
  RichTextModuleData,
  FooterFormModuleData,
} from "ecom-types";

interface State {
  footer: {
    copyright: string | undefined;
    leftColumn: BlockContent | undefined;
    rightColumn: string | undefined;
    centerColumn: string | undefined;
    certificatesEntities: RichTextModuleData | null | undefined;
    bottomInfo: RichTextModuleData | null | undefined;
    footerForm: FooterFormModuleData | null | undefined;
  };
  isSignInDrawerOpen: boolean;
  isSignUpDrawerOpen: boolean;
  systemData: {
    loginFooter: RichTextModuleData | null;
  };
  systemOverlay: boolean;
}

export const useGeneralInternalStore = defineStore("generalInternal", {
  state: (): State => ({
    footer: {
      copyright: "",
      leftColumn: undefined,
      rightColumn: "",
      centerColumn: "",
      certificatesEntities: null,
      bottomInfo: null,
      footerForm: null,
    },
    isSignInDrawerOpen: false,
    isSignUpDrawerOpen: false,
    systemData: {
      loginFooter: null,
    },
    systemOverlay: false,
  }),

  actions: {
    async getBlocks() {
      const { $api } = useNuxtApp();
      const { data: apiData } = await useAsyncData(
        "getBaseBlocks",
        async () => {
          const [
            responseCopyright,
            responseFooterLeftColumn,
            responseSystemLoginFooter,
            responseFooterForm,
          ] = await Promise.all([
            $api.blocks.getBlock("footer-copyright"),
            $api.blocks.getBlock("footer-left-column"),
            $api.blocks.getBlock("system-login-footer"),
            $api.blocks.getBlock("footer-form"),
          ]);
          return {
            dataCopyright: responseCopyright.data,
            dataFooterLeftColumn: responseFooterLeftColumn.data,
            dataSystemLoginFooter: responseSystemLoginFooter.data,
            dataFooterForm: responseFooterForm.data,
          };
        },
      );

      const {
        dataCopyright,
        dataFooterLeftColumn,
        dataSystemLoginFooter,
        dataFooterForm,
      } = apiData.value || {};

      this.footer.copyright =
        (dataCopyright?.currentTranslation as IBlockTranslation<BlockContent>)
          ?.content?.content || "";

      this.systemData.loginFooter =
        ((
          dataSystemLoginFooter?.currentTranslation as IBlockTranslation<BlockContent>
        )?.content as RichTextModuleData) || "";

      this.footer.leftColumn =
        (
          dataFooterLeftColumn?.currentTranslation as IBlockTranslation<BlockContent>
        )?.content || "";

      this.footer.footerForm =
        ((dataFooterForm?.currentTranslation as IBlockTranslation<BlockContent>)
          ?.content as FooterFormModuleData) || "";
    },
  },
});
