import { type VariantProps, cva } from "class-variance-authority";

export { default as UiButton } from "./UiButton.vue";

export const buttonVariants = cva("btn", {
  variants: {
    variant: {
      default: "",
      outlined: "",
      text: "",
      clear: "min-h-0",
    },
    variantType: {
      default: "",
      tile: "",
      icon: "btn-rounded",
    },
    color: {
      primary: "",
      secondary: "",
      white: "",
    },
    size: {
      xs: "",
      sm: "btn-sm",
      md: "",
    },
  },
  compoundVariants: [
    {
      variant: "default",
      color: "primary",
      class: "btn-primary",
    },
    {
      variant: "default",
      color: "secondary",
      class: "btn-secondary",
    },
    {
      variant: "default",
      color: "white",
      class: "btn-white",
    },
    {
      variant: "outlined",
      color: "primary",
      class: "btn-outline-primary",
    },
    {
      variant: "outlined",
      color: "secondary",
      class: "btn-outline-secondary",
    },
    {
      variant: "outlined",
      color: "white",
      class: "btn-outline-white",
    },
    {
      variant: "text",
      color: "primary",
      class: "btn-text-primary",
    },
    {
      variant: "text",
      color: "secondary",
      class: "btn-text-secondary",
    },
    {
      variant: "text",
      color: "white",
      class: "btn-text-white",
    },
    {
      variant: "clear",
      color: "primary",
      class: "",
    },
    {
      variantType: "icon",
      size: "xs",
      class: "size-6 !min-h-auto p-0",
    },
    {
      variantType: "icon",
      size: "sm",
      class: "size-10 min-h-auto p-0",
    },
    {
      variantType: "icon",
      size: "md",
      class: "size-12 min-h-auto p-0",
    },
  ],
  defaultVariants: {
    variant: "default",
    color: "primary",
    size: "md",
    variantType: "default",
  },
});

export type ButtonVariants = VariantProps<typeof buttonVariants>;
