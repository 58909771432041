export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("page:finish", () => {
    // TODO remove if lazy load will works fine
    // console.log(111);
    // setTimeout(() => {
    //   window.scrollTo({ top: 0, behavior: "auto" });
    // }, 100);
    // window.scrollTo({ top: 0, behavior: "auto" });
  });
});
