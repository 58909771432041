import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import clear_45url_45duplicates_45global from "/app/node_modules/.c12/github_y_media_A8FrquQPxr/middleware/clearUrlDuplicates.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  clear_45url_45duplicates_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/middleware/auth.ts"),
  guest: () => import("/app/middleware/guest.ts"),
  "guest-order-tracking": () => import("/app/middleware/guestOrderTracking.ts"),
  "redirect-blog": () => import("/app/middleware/redirectBlog.ts")
}