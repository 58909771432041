import { useGeneralInternalStore } from "~/stores/generalInternal";

export default defineNuxtPlugin({
  name: "general",
  async setup() {
    const { appInit, getLanguages, fetchUserInfo } = useGeneralStore();
    const { getBlocks } = useGeneralInternalStore();

    // const route = useRoute();
    // const cartUniqueId = useCookie("cart_unique_id");
    // let getCartByCartID = false; // TODO refactor it
    // const cartUniqueIdRoutes = ["checkout", "cart"];
    // if (
    //   cartUniqueIdRoutes.some((path) => route.path.split("/").includes(path)) &&
    //   route.params.id &&
    //   route.params.id !== cartUniqueId.value
    // ) {
    //   cartUniqueId.value = route.params.id as string;
    //   getCartByCartID = true;
    // }

    getLanguages();
    await Promise.all([appInit(), getBlocks(), fetchUserInfo()]);
  },
});
