<template>
  <NuxtLoadingIndicator color="#000" />
  <NuxtLayout>
    <NuxtPage />
    <DialogsSystemDialog />
    <UiButton
      v-if="$config.public.isDev"
      to="/ui-kit"
      class="fixed bottom-4 right-4 px-4"
      color="primary"
    >
      To UiKit
    </UiButton>
  </NuxtLayout>
</template>

<script setup lang="ts">
import { IntegrationsType } from "ecom-types";

const generalStore = useGeneralStore();
const { setIrClickId } = useIrClickId();
const { filesListLinks, filesListMeta } = useMetaIcons();

setIrClickId();

const gtmIntegration =
  generalStore.bootstrap?.integrations?.[IntegrationsType.TYPE_GTM];

const headMeta = [...filesListMeta];

if (gtmIntegration && gtmIntegration.verification_code) {
  headMeta.push({
    "data-hid": "google-site-verification",
    name: "google-site-verification",
    content: gtmIntegration.verification_code,
  });
}

useHead({
  link: [...filesListLinks],
  meta: headMeta,
});
</script>
