import type { RouterConfig } from "@nuxt/schema";

function scrollTopWithOffset(hash: string) {
  const element = document.querySelector(hash);
  const headerHeight =
    parseInt(
      document.documentElement.style.getPropertyValue("--header-height"),
    ) || 0;

  if (element) {
    return element.getBoundingClientRect().top + window.scrollY - headerHeight;
  } else {
    return 0;
  }
}
export default <RouterConfig>{
  scrollBehavior: (to, from, savedPosition) => {
    const nuxtApp = useNuxtApp();

    // scroll to hash, useful for using to="#some-id" in NuxtLink
    if (to.hash) {
      return new Promise((resolve) => {
        const observer = new MutationObserver((_, observer) => {
          if (document.querySelector(to.hash)) {
            observer.disconnect();
            resolve({
              top: scrollTopWithOffset(to.hash),
              behavior: "smooth",
            });
          }
        });

        observer.observe(document.body, {
          childList: true,
          subtree: true,
        });
      });
    }

    // if link is to same page, scroll to top with smooth behavior
    if (to.fullPath === from.fullPath) {
      return {
        left: 0,
        top: 0,
        behavior: "smooth",
      };
    }

    if (to.path !== from.path) {
      return new Promise((resolve) => {
        nuxtApp.hooks.hookOnce("page:finish", () => {
          resolve({
            top: 0,
          });
        });
      });
    } else {
      return new Promise((resolve) => {
        nuxtApp.hooks.hookOnce("page:finish", () => {
          resolve({
            top: savedPosition?.top || 0,
          });
        });
      });
    }
  },
};
