<template>
  <component :is="icon" v-if="icon" />
</template>

<script setup lang="ts">
const props = defineProps<{
  name: string;
  skipSvgo?: boolean;
}>();

const icon = ref();
await loadIcon();

watch([() => props.name, () => props.skipSvgo], async () => {
  await loadIcon();
});

async function loadIcon() {
  try {
    const iconsImport = props.skipSvgo
      ? import.meta.glob(`/assets/icons/**/**.svg`, {
          query: "?skipsvgo",
        })
      : import.meta.glob(`/assets/icons/**/**.svg`);
    icon.value = await iconsImport[`/assets/icons/${props.name}.svg`]();
  } catch {
    console.error(`Icon '${props.name}' doesn't exist in 'assets/icons'`);
  }
}
</script>
