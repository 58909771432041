import { useSystemDialogStore } from "~/stores/systemDialog";
type SystemDialogType = "alert" | "confirm";

interface SystemDialogInterface {
  type: SystemDialogType;
  text: string;
  title?: string;
  successBtnText?: string;
  cancelBtnText?: string;
  successHandler?: any;
  cancelHandler?: any;
}

const DEFAULT_DIALOG_STATE: SystemDialogInterface = {
  type: "alert",
  title: "",
  text: "",
  successBtnText: "Ok",
  cancelBtnText: "Cancel",
  successHandler: null,
  cancelHandler: null,
};

export const useSystemDialogs = () => {
  const systemDialogStore = useSystemDialogStore();

  const isConfirm = computed(() => {
    return systemDialogStore.dialogState.type === "confirm";
  });

  function openSystemDialog(option: SystemDialogInterface) {
    systemDialogStore.dialogState = {
      ...systemDialogStore.dialogState,
      ...option,
    };
    systemDialogStore.isOpen = true;
  }

  function closeSystemDialog() {
    if (isConfirm.value) {
      cancel();
    } else {
      success();
    }
  }

  function cancel() {
    if (
      systemDialogStore.dialogState.cancelHandler &&
      typeof systemDialogStore.dialogState.cancelHandler === "function"
    ) {
      systemDialogStore.dialogState.cancelHandler();
    }
    nextTick().then(() => {
      closeModal();
    });
  }

  function success() {
    if (
      systemDialogStore.dialogState.successHandler &&
      typeof systemDialogStore.dialogState.successHandler === "function"
    ) {
      systemDialogStore.dialogState.successHandler();
    }
    nextTick().then(() => {
      closeModal();
    });
  }

  function closeModal() {
    systemDialogStore.dialogState = { ...DEFAULT_DIALOG_STATE };
    systemDialogStore.isOpen = false;
  }

  return {
    // isOpen,
    // dialogState,
    openSystemDialog,
    cancel,
    success,
    isConfirm,
    closeSystemDialog,
  };
};
