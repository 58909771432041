<template>
  <NuxtLayout name="default">
    <div class="max-w-200 container mx-auto px-4 py-10">
      <div class="py-6 lg:py-10">
        <h1 class="ui-h3 mb-6">
          {{ errorData.title }}
        </h1>
        <ul class="list-disc space-y-3 pl-8">
          <li
            v-for="(step, idx) in errorDescriptionSteps"
            :key="idx"
            @click="contentLink"
            v-html="step"
          />
        </ul>
        <div class="hidden" data-error-text>
          {{ error?.message }}
        </div>
        <div class="hidden" data-error-code>
          {{ error?.statusCode }}
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
import { NuxtError } from "#app";

const props = defineProps<{
  error: NuxtError;
}>();

const { t } = useI18n();

const generalStore = useGeneralStore();
const { filesListLinks, filesListMeta } = useMetaIcons();

useHead({
  link: [...filesListLinks],
  meta: [...filesListMeta],
});

useSeoMeta({
  title: () => generalStore.settings?.company_name || "",
  ogTitle: () => generalStore.settings?.company_name || "",
  description: () => generalStore.settings?.company_name || "",
  ogDescription: () => generalStore.settings?.company_name || "",
});

// const handleError = () => clearError({ redirect: "/" });

const errorData = computed(() => {
  switch (props.error?.statusCode) {
    case 400:
      return {
        title: t("yourRequestCouldNotBeUnderstood"),
      };
    case 403:
      return {
        title: t("youDoNotHavePermission"),
      };
    case 404:
      return {
        title: t("notFoundTitle"),
      };
    case 500:
      return {
        title: t("internalServerError", { status: props.error.statusCode }),
      };
    case 502:
      return {
        title: t("badGateway", { status: props.error.statusCode }),
      };
    case 503:
      return {
        title: t("serviceUnavailable", { status: props.error.statusCode }),
      };
    case 504:
      return {
        title: t("gatewayTimeout", { status: props.error.statusCode }),
      };
    case 505:
      return {
        title: t("httpVersionNotSupported", { status: props.error.statusCode }),
      };
    case 522:
      return {
        title: t("connectionTimedOut", { status: props.error.statusCode }),
      };
    default:
      return {
        title: `${props.error?.statusCode} ${props.error?.message}`,
      };
  }
});

const contentLink = useContentLink;

const errorDescriptionSteps = [
  t("ifYouTypedTheUrlDirectlyPleaseMakeSureTheSpellingIsCorrect"),
  t("ifYouClickedOnALinkToGetHere,WeMustHaveMovedTheContent"),
  t("pleaseTryOurStoreSearchBoxAboveToSearchForAnItem"),
  t(
    "ifYouAreNotSureHowYouGotHereTryStartingAtOurHomepageToNavigateToWhatYouAreLookingFor",
  ),
];
</script>
