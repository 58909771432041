import { IntegrationsType } from "ecom-types";
import { FetchError } from "ofetch";

export const useIrClickId = () => {
  const { $api } = useNuxtApp();
  const route = useRoute();
  const generalStore = useGeneralStore();

  const { irclickid } = useCookiesService();

  function setIrClickId() {
    if (!generalStore.isIntegrationEnabled(IntegrationsType.TYPE_IMPACT)) {
      return;
    }
    if (route.query?.irclickid) {
      irclickid.value = route.query.irclickid as string;
    }
  }
  function removeIrClickId() {
    irclickid.value = null;
  }
  async function sendIrClickId(orderId: string | number | undefined) {
    if (
      !irclickid.value ||
      !orderId ||
      !generalStore.isIntegrationEnabled(IntegrationsType.TYPE_IMPACT)
    ) {
      return;
    }
    try {
      const res = await $api.general.sendIrclickidImpact({
        orderId: String(orderId),
        irClickId: irclickid.value,
      });
      return res;
    } catch (error) {
      if (error instanceof FetchError) {
        console.log("error - ", error?.response);
      }
    }
  }
  return {
    setIrClickId,
    removeIrClickId,
    sendIrClickId,
  };
};
