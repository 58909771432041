import { defineStore } from "pinia";

type SystemDialogType = "alert" | "confirm";
interface SystemDialogInterface {
  type: SystemDialogType;
  text: string;
  title?: string;
  successBtnText?: string;
  cancelBtnText?: string;
  successHandler?: any;
  cancelHandler?: any;
}

interface State {
  dialogState: SystemDialogInterface;
  isOpen: boolean;
}
export const useSystemDialogStore = defineStore("systemDialog", {
  state: (): State => ({
    dialogState: {
      type: "alert",
      title: "",
      text: "",
      successBtnText: "Ok",
      cancelBtnText: "Cancel",
      successHandler: null,
      cancelHandler: null,
    },
    isOpen: false,
  }),
});
